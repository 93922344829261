<template>
  <div class="uk-margin uk-width-expand">
    <label
      class="uk-form-label uk-text-right"
      style="width: auto; margin-top: 3px"
      for="selectNetwork"
    >
      {{ label ? label : "network" }}
    </label>
    <div class="uk-form-controls">
      <Select
        :id="id ? id : 'selectNetwork'"
        :class="boundaryClass ? boundaryClass : 'hfi-network-boundary'"
        :boundaryClass="boundaryClass ? boundaryClass : 'hfi-network-boundary'"
        selectClasses="uk-width-expand"
        inputClasses="uk-text-left hfi-network-button"
        :options="networks"
        :values="networkNames"
        :icons="networkIcons"
        :sortOptions="sortOptions"
        :value="value || network || Network.arbitrum"
        @change="setNetwork"
        :disabled="disabled"
        offset="-2"
      />
    </div>
  </div>
</template>

<script>
import Select from "@/components/Select";
import { store } from "@/store";
import { NETWORK_NAMES } from "@/utils/constants/networks";
import Network from "@/types/Network";

export default {
  name: "SelectNetwork",
  components: {
    Select,
  },
  props: [
    "id",
    "networks",
    "changeFn",
    "value",
    "boundaryClass",
    "disabled",
    "selectClasses",
    "inputClasses",
    "label",
    "sortOptions",
  ],
  data() {
    return {
      networkIcons: [
        "arbitrumLogo.svg",
        "homesteadLogo.svg",
        "polygonLogo.svg",
      ],
      networkNames: [
        { value: Network.arbitrum, name: NETWORK_NAMES[Network.arbitrum] },
        { value: Network.homestead, name: NETWORK_NAMES[Network.homestead] },
        { value: Network.polygon, name: NETWORK_NAMES[Network.polygon] },
      ],
      Network,
    };
  },
  computed: {
    network() {
      return store.state.network;
    },
  },
  methods: {
    setNetwork(value) {
      if (value === this.value) return;
      this.$emit(this.changeFn, value);
    },
  },
};
</script>

<style type="scss">
#selectNetwork-dropdown {
  min-width: auto !important;
}
</style>
