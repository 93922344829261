<template>
  <div
    class="uk-container uk-container-xlarge hfi-dashboard-container uk-form-width-large uk-flex-center"
  >
    <vue-headful
      description="move tokens between l1 &amp; l2 networks"
      image="src/assets/logo.png"
      :title="`${isLite ? 'handleLite' : 'handle.fi'} | bridge`"
    />
    <h2 class="uk-margin-remove-bottom">bridge</h2>

    <div uk-grid class="uk-grid-small uk-margin-small-top uk-child-width-1-3">
      <div class="uk-flex uk-width-expand">
        <SelectNetwork
          id="fromNetwork"
          label="from"
          :networks="networks"
          boundaryClass="fromNetwork"
          :value="network"
          :sortOptions="true"
          :disabled="!canBridge"
          changeFn="changeFromNetwork"
          @changeFromNetwork="setNetwork"
        />
      </div>

      <div
        style="width: 40px; margin-top: 8px"
        class="uk-flex uk-flex-center uk-flex-middle"
      >
        <div class="uk-margin-top uk-width-1-1 uk-flex uk-flex-center">
          <button
            class="uk-icon-button"
            :uk-tooltip="
              canBridge ? 'title: switch networks; pos: bottom;' : undefined
            "
            :disabled="!canBridge"
            @click.prevent="swapDirection"
          >
            <i class="fal fa-exchange"></i>
          </button>
        </div>
      </div>

      <div class="uk-flex uk-width-expand">
        <SelectNetwork
          id="toNetwork"
          label="to"
          :networks="networks.filter((nwk) => nwk !== network)"
          boundaryClass="toNetwork"
          :value="oppositeNetwork"
          :sortOptions="true"
          :disabled="!canBridge"
          changeFn="changeToNetwork"
          @changeToNetwork="setOppositeNetwork"
        />
      </div>
    </div>

    <BridgeForm
      :balance="selectedToken ? selectedToken.balance : ethers.constants.Zero"
      :network="network"
      :currentNetwork="true"
      :canBridge="canBridge"
      :inputAmount="amount"
      @setBridgeToken="onSelectToken"
      @setAmount="setAmount"
    />

    <BridgeButton
      :token="selectedToken"
      :amount="amount"
      :loading="loading || switchingNetwork"
      :canBridge="canBridge"
      :oppositeNetwork="oppositeNetwork"
      :providers="{
        arbitrum: arbitrumProvider,
        homestead: homesteadProvider,
        polygon: polygonProvider,
      }"
      :tokenList="tokenList || []"
      @new-pending-withdrawal="setPendingWithdrawal"
      @bridge-successful="onSuccessfulBridge"
      @withdrawal-successful="onSuccessfulWithdrawal"
    />
  </div>
</template>

<script>
import { store } from "@/store";
import Network from "@/types/Network";
import Token from "@/types/Token";
import {
  homesteadProvider,
  arbitrumProvider,
  polygonProvider,
  switchNetwork,
} from "@/utils/wallet";
import { showNotification } from "@/utils/utils";
import { ethers } from "ethers";
import BridgeBalanceTable from "@/components/BridgeBalanceTable";
import NetworkIcon from "@/components/NetworkIcon";
import BridgeForm from "@/components/BridgeForm";
import SelectNetwork from "@/components/SelectNetwork";
import BridgeButton from "@/components/BridgeButton";
import contracts from "../contracts.config.json";
import getErc20 from "@/contracts/ERC20/createInstance";

export default {
  name: "Bridge",
  components: {
    BridgeForm,
    BridgeBalanceTable,
    NetworkIcon,
    SelectNetwork,
    BridgeButton,
  },
  data() {
    return {
      selectedToken: undefined,
      oppositeNetwork: undefined,
      provider: undefined,
      oppositeProvider: undefined,
      pendingWithdrawal: undefined,
      oppositeTokenList: [],
      amount: ethers.BigNumber.from(0),
      switchingNetwork: false,
      loading: false,
      homesteadProvider,
      arbitrumProvider,
      polygonProvider,
      ethers,
    };
  },
  async mounted() {
    await this.init();
  },
  computed: {
    isLite() {
      return store.state.isLite;
    },
    network() {
      return store.state.network;
    },
    account() {
      return store.state.account;
    },
    convertSDK() {
      return store.state.refConvertSDK.get();
    },
    tokenList() {
      // we only support handle tokens at this point
      // so we only need to fetch the token list for the connected
      // network as addresses are the same
      const SUPPORTED_TOKENS = [
        Token.FOREX,
        Token.fxAUD,
        Token.fxPHP,
        Token.fxEUR,
      ];

      return SUPPORTED_TOKENS.map((symbol) => ({
        symbol,
        decimals: symbol === Token.FOREX ? 4 : 2,
        address:
          contracts.arbitrum[
            symbol === Token.FOREX ? symbol.toLowerCase() : symbol
          ],
        balance: ethers.constants.Zero,
      }));
    },
    pendingWithdrawalAlertMessage() {
      return `pending ${ethers.utils.formatUnits(
        ethers.BigNumber.from(this.pendingWithdrawal.amount),
        this.pendingWithdrawal.tokenDecimals
      )}
        ${this.pendingWithdrawal.tokenSymbol} withdrawal`;
    },
    canBridge() {
      return (
        this.account &&
        !this.loading &&
        !this.switchingNetwork &&
        this.tokenList.length > 0 &&
        this.selectedToken != null
      );
    },
    networks() {
      return this.$route.meta.networks.filter((nwk) => nwk !== "hardhat");
    },
  },
  watch: {
    async network() {
      await this.init();
    },
    async account() {
      await this.init();
    },
    async oppositeNetwork() {
      this.oppositeProvider = this[this.oppositeNetwork + "Provider"];
    },
    async tokenList() {
      await this.fetchBalances();
      if (this.tokenList.length > 0) this.onSelectToken("FOREX");
    },
  },
  methods: {
    async init() {
      if (!this.network) return;

      this.provider = this[this.network + "Provider"];

      if (!this.provider) return;

      this.loading = true;
      await store.dispatch("updateTokenList");
      this.oppositeNetwork =
        this.network === Network.homestead
          ? Network.arbitrum
          : Network.homestead;
      await this.onSelectToken("FOREX");
      this.loading = false;
      this.switchingNetwork = false;
    },
    async fetchBalances() {
      await store.dispatch("updateBalances", this.tokenList);
    },
    async onSelectToken(token) {
      this.loading = true;
      // must load balance.
      this.selectedToken = this.tokenList.find((x) => x.symbol === token);
      if (this.selectedToken) {
        // clear balance while loading.
        this.selectedToken.balance = ethers.constants.Zero;
        this.selectedToken.balance = await getErc20(
          this.provider,
          this.selectedToken.address
        ).balanceOf(this.account);
        this.amount = ethers.BigNumber.from(0);
      }
      this.loading = false;
    },
    async setPendingWithdrawal(data) {
      this.pendingWithdrawal = data;
      await this.fetchBalances();
    },
    async onSuccessfulBridge() {
      this.amount = ethers.BigNumber.from(0);
      await this.fetchBalances();
      this.selectedToken.balance = await getErc20(
        this.provider,
        this.selectedToken.address
      ).balanceOf(this.account);
    },
    async onSuccessfulWithdrawal() {
      this.removePendingWithdrawal();
      await this.fetchBalances();
      this.selectedToken.balance = await getErc20(
        this.provider,
        this.selectedToken.address
      ).balanceOf(this.account);
    },
    removePendingWithdrawal() {
      this.pendingWithdrawal = undefined;
    },
    setAmount(amount) {
      this.amount = amount;
    },
    async setNetwork(network) {
      this.switchingNetwork = true;
      const notification = await showNotification(
        "warning",
        "confirm switch of network...",
        undefined,
        0
      );
      try {
        await switchNetwork(network);
      } catch (e) {
        showNotification("error", e.message ? e.message.toLowerCase() : e);
        this.switchingNetwork = false;
      }
      notification.close();
    },
    setOppositeNetwork(network) {
      this.oppositeNetwork = network;
    },
    swapDirection() {
      this.setNetwork(this.oppositeNetwork);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../assets/styles/handle.fi" as handle;
.pending-withdrawal-button {
  text-align: left;
  padding: 0 15px;
}
</style>
