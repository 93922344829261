<template>
  <div class="bridge-balance-table">
    <div>
      <NetworkIcon
        :network="network"
        class="uk-margin-small-right"
        height="25"
        width="25"
      />
      <span>{{ networkDisplayName }}</span>
    </div>
    <table
      class="uk-table uk-table-responsive uk-table-xs uk-table-divider uk-table-middle uk-margin-small-top"
    >
      <thead class="hfi-transactions-table-header hfi-border-top-remove">
        <tr>
          <th>token</th>
          <th class="uk-text-right">balance</th>
          <th v-if="!!currentNetwork"></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="token in balances" :key="token.symbol">
          <td data-label="symbol">
            <img width="24" :src="token.icon" />
            {{ token.symbol }}
          </td>
          <td data-label="balance" class="uk-text-right">
            {{ displayBalance(token) }}
          </td>
          <td v-if="currentNetwork === true" class="uk-text-right">
            <button
              @click="bridgeToken(token)"
              class="uk-button hfi-button uk-button-small bridge-button"
              :disabled="disableButton(token)"
            >
              <i class="fal fa-arrow-right"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { NETWORK_NAMES } from "@/utils/constants/networks";
import Network from "@/types/Network";
import { ethers } from "ethers";
import NetworkIcon from "@/components/NetworkIcon";

export default {
  name: "BridgeBalanceTable",
  components: {
    NetworkIcon,
  },
  props: {
    currentNetwork: { type: Boolean },
    balances: { type: Array, default: () => [] },
    network: { type: String },
    pendingWithdrawal: { type: Object },
  },
  data() {
    return {};
  },
  computed: {
    networkDisplayName() {
      return NETWORK_NAMES[this.network];
    },
    networkImageSrc() {
      return this.network === Network.homestead
        ? "/ethereumLogo.svg"
        : "/arbitrumLogo.svg";
    },
    balance() {
      this.currentNetwork ? balance.currentBalance : balance.oppositeBalance;
    },
  },
  methods: {
    bridgeToken: function (token) {
      this.$emit("selectBridgeToken", token);
    },
    displayBalance: function (token) {
      return ethers.utils.formatUnits(
        this.currentNetwork ? token.currentBalance : token.oppositeBalance,
        token.decimals
      );
    },
    disableButton: function (token) {
      return (
        (this.currentNetwork
          ? token.currentBalance
          : token.oppositeBalance
        ).isZero() || this.pendingWithdrawal
      );
    },
  },
};
</script>
<style lang="scss">
@use "src/assets/styles/handle.fi.scss" as handle;
.bridge-balance-table {
  .bridge-button {
    height: 21px;
    line-height: 21px;
  }
}
</style>
