<template>
  <div>
    <div class="uk-margin-top uk-flex">
      <div class="uk-flex uk-flex-1">
        <button
          v-if="canSubmitBridge"
          id="bridgeButton"
          class="uk-button uk-button-primary hfi-button uk-width-expand hfi-forex-button"
          type="button"
          @click="bridge"
          :disabled="bridging"
        >
          <vue-loaders-ball-pulse
            v-if="bridging"
            color="currentColor"
            scale="0.5"
            style="margin-bottom: -6px"
          />
          <span v-else>{{ bridgeButtonText }}</span>
        </button>

        <button
          v-else-if="canApprove"
          id="approveButton"
          class="uk-button uk-button-primary hfi-button uk-width-expand hfi-forex-button"
          type="button"
          :disabled="approving || !canBridge || amount.eq(0)"
          @click="approve"
        >
          <vue-loaders-ball-pulse
            v-if="loading || approving"
            color="currentColor"
            scale="0.5"
            style="margin-bottom: -6px"
          />
          <span v-else>
            {{ approveButtonText }}
          </span>
        </button>

        <button
          v-else
          id="otherButton"
          class="uk-button uk-button-primary hfi-button uk-width-expand hfi-forex-button"
          type="button"
          :disabled="true"
        >
          <span v-if="loading && account">
            initiating token bridge
            <vue-loaders-ball-pulse
              color="currentColor"
              scale="0.5"
              style="margin-bottom: -6px"
            />
          </span>
          <span v-else>
            {{ otherButtonText }}
          </span>
        </button>
      </div>
    </div>

    <div v-if="!loading && account && pendingWithdrawal" class="uk-margin-top">
      <button
        id="withdrawButton"
        class="uk-button uk-margin-xsmall-top uk-button-primary uk-width-expand hfi-button hfi-forex-button"
        type="button"
        v-if="loadingPendingDeposits"
        :disabled="true"
        key="0"
      >
        loading pending withdrawals
        <vue-loaders-ball-pulse
          color="currentColor"
          scale="0.5"
          style="margin-left: -8px; margin-bottom: -6px"
        />
      </button>

      <button
        v-else
        id="withdrawButton"
        class="uk-button uk-margin-xsmall-top uk-button-primary uk-width-expand hfi-button hfi-forex-button"
        type="button"
        v-for="deposit in pendingUserDeposits"
        :disabled="deposit.pendingIndex > 0 || bridging || withdrawing"
        :key="deposit.txHash"
        @click="() => withdraw(deposit)"
      >
        <vue-loaders-ball-pulse
          v-if="(loading && account) || withdrawing"
          color="currentColor"
          scale="0.5"
          style="margin-bottom: -6px"
        />
        <span v-else
          >{{
            pendingUserDeposits.length > 1
              ? `${deposit.pendingIndex + 1}: `
              : ""
          }}
          withdraw {{ ethers.utils.formatEther(deposit.amount) }}
          {{ tokenSymbolFromAddress(deposit.token) }} to
          {{ NETWORK_NAMES[bridgeIdToNetwork[deposit.toId]] }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { store } from "@/store";
import { ethers, BigNumber } from "ethers";
import { signer, switchNetwork } from "@/utils/wallet";
import Network from "@/types/Network";
import BridgeId from "@/types/BridgeId";
import createErc20 from "@/contracts/ERC20/createInstance";
import { NETWORK_NAMES } from "@/utils/constants/networks";
import bridgeABI from "@/abi/handleBridge";
import { getSignature } from "@/utils/handleBridgeApi";
import { showNotification, closeAllNotifications } from "@/utils/utils";
import Token from "@/types/Token";

const NETWORK_TO_BRIDGE_ADDRESS_MAP = {
  [Network.homestead]: "0xA112D1bFd43fcFbF2bE2eBFcaebD6B6DB73aaD8B",
  [Network.arbitrum]: "0x000877168981dDc3CA1894c2A8979A2F0C6bBF3a",
  [Network.polygon]: "0x62E13B35770D40aB0fEC1AB7814d21505620057b",
};

/**
 * @typedef {{
 *   txHash: string,
 *   depositor: string,
 *   token: string,
 *   amount: ethers.BigNumber,
 *   nonce: ethers.BigNumber,
 *   fromId: ethers.BigNumber,
 *   toId: ethers.BigNumber,
 *   pendingIndex: number
 * }} DepositEvent
 */

export default {
  name: "BridgeButton",
  components: {},
  props: {
    isOpen: { type: Boolean },
    token: { type: Object },
    loading: { type: Boolean },
    canBridge: { type: Boolean },
    oppositeNetwork: { type: String },
    /** @type {Object.<string, ethers.Provider>} */
    providers: { type: Object },
    amount: { type: BigNumber },
    tokenList: { type: Array },
  },
  data() {
    return {
      ethers,
      approving: false,
      bridging: false,
      switchingNetwork: false,
      withdrawing: false,
      allowances: {},
      /** @type DepositEvent user deposits that haven't yet been withdrawn */
      pendingUserDeposits: [],
      formatEther: (
        value,
        digits = this.token.symbol === Token.FOREX ? 4 : 2
      ) =>
        (value
          ? parseFloat(ethers.utils.formatEther(value))
          : 0
        ).toLocaleString(undefined, this.digits(digits)),
      NETWORK_NAMES,
      loadingPendingDeposits: false,
    };
  },

  computed: {
    network() {
      return store.state.network;
    },
    account() {
      return store.state.account;
    },
    bridgeAddress() {
      return NETWORK_TO_BRIDGE_ADDRESS_MAP[this.network];
    },
    bridgeContract() {
      return new ethers.Contract(this.bridgeAddress, bridgeABI, signer);
    },
    tokenContract() {
      return this.token ? createErc20(signer, this.token.address) : undefined;
    },
    pendingWithdrawal() {
      return this.pendingUserDeposits.length > 0;
    },
    balance() {
      return this.token?.balance
        ? ethers.utils.formatUnits(this.token.balance, this.token.decimals)
        : ethers.constants.Zero;
    },
    hasEnoughBalance() {
      return this.token?.balance.gt(0) && this.token?.balance.gte(this.amount);
    },
    hasEnoughAllowance() {
      return !this.token
        ? false
        : this.allowances[this.token.symbol]?.gte(this.amount);
    },
    networkDisplayName() {
      return store.getters.networkDisplayName;
    },
    canApprove() {
      return (
        this.approving ||
        (!this.hasEnoughAllowance &&
          this.hasEnoughBalance &&
          !this.amount.isZero())
      );
    },
    canSubmitBridge() {
      return (
        this.canBridge &&
        (this.bridging ||
          (this.hasEnoughAllowance &&
            this.hasEnoughBalance &&
            !this.amount.isZero()))
      );
    },
    bridgeIdToNetwork() {
      const bridgeIdToNetwork = {};
      for (let network of Object.keys(BridgeId)) {
        bridgeIdToNetwork[BridgeId[network]] = network;
      }
      return bridgeIdToNetwork;
    },
    approveButtonText() {
      return `approve bridge of ${this.tokenSymbol}${
        this.canSubmitBridge ? " on " + NETWORK_NAMES[this.network] : ""
      }`;
    },
    bridgeButtonText() {
      return `bridge ${this.tokenSymbol}`;
    },
    otherButtonText() {
      return this.account ? "bridge" : "please connect wallet";
    },
    tokenSymbol() {
      return this.token ? this.token.symbol : "";
    },
  },

  watch: {
    network() {
      if (this.token) this.resetState();
    },
    account() {
      if (this.token) this.resetState();
    },
    token() {
      if (this.token) this.resetState();
    },
  },

  mounted() {
    if (this.token) this.resetState();
  },

  methods: {
    resetState() {
      this.getAllowance();
      this.fetchPendingDeposits();
    },
    tokenSymbolFromAddress(address) {
      const token = this.tokenList.find(
        (x) => x.address.toLowerCase() === address.toLowerCase()
      );
      return token?.symbol ?? "???";
    },
    async getAllowance() {
      try {
        const allowance = await this.tokenContract.allowance(
          this.account,
          this.bridgeAddress
        );

        this.allowances = {
          ...this.allowances,
          [this.token.symbol]: allowance,
        };
      } catch (e) {
        console.log("Get allowances error:", e);
      }
    },

    async approve() {
      this.approving = true;
      try {
        closeAllNotifications();
        showNotification(
          "warning",
          "follow wallet instructions to approve spend",
          undefined,
          0
        );
        const tx = await this.tokenContract.approve(
          this.bridgeAddress,
          ethers.constants.MaxUint256
        );

        await tx.wait(1);
        closeAllNotifications();
        showNotification("success", "spend approved successfully");
        await this.getAllowance();
      } catch (error) {
        showNotification(
          "error",
          error.data?.message
            ? error.data.message
            : error.message.toLowerCase(),
          undefined,
          0
        );
      }
      this.approving = false;
    },

    async bridge() {
      this.bridging = true;

      try {
        closeAllNotifications();
        showNotification(
          "warning",
          "follow wallet instructions to approve bridge",
          undefined,
          0
        );
        console.log(
          `bridging towards bridge id ${BridgeId[this.oppositeNetwork]}`
        );
        const receipt = await this.bridgeContract.deposit(
          this.token.address,
          this.amount,
          BridgeId[this.oppositeNetwork],
          {
            gasLimit:
              this.network === Network.homestead
                ? ethers.BigNumber.from("101806")
                : undefined,
          }
        );

        await receipt.wait(1);
        closeAllNotifications();
        showNotification("success", "tokens bridged successfully");

        this.$emit("new-pending-withdrawal", {
          txHash: receipt.hash,
          fromNetwork: this.network,
          toNetwork: this.oppositeNetwork,
          tokenSymbol: this.token.symbol,
          tokenAddress: this.token.address,
          amount: this.amount.toString(),
        });
      } catch (error) {
        console.log(error);
        closeAllNotifications();
        showNotification(
          "error",
          error.data?.message
            ? error.data.message
            : error.message.toLowerCase(),
          undefined,
          0
        );
      }
      this.$emit("bridge-successful");
      this.bridging = false;
      this.fetchPendingDeposits();
    },

    async fetchPendingDeposits() {
      this.loadingPendingDeposits = true;
      this.pendingUserDeposits = [];
      const networks = Object.keys(NETWORK_TO_BRIDGE_ADDRESS_MAP);
      /** @type Object.<string, DepositEvent[]> */
      const networkDeposits = {};
      let promises = [];
      /** @type Object<string, Object<string, ethers.BigNumber>> */
      const actualWithdrawNonces = {};
      /** The withdrawNonces required to complete all pending deposits.
       * depositNetwork => withdrawNetwork => nonce
       * @type Object<string, Object<string, ethers.BigNumber>> */
      const filledWithdrawNonces = {};
      const getContract = (network) =>
        new ethers.Contract(
          NETWORK_TO_BRIDGE_ADDRESS_MAP[network],
          bridgeABI,
          this.providers[network]
        );
      // Fetch deposits for each network.
      for (let network of networks) {
        promises.push(
          new Promise(async (resolve) => {
            const contract = getContract(network);
            const filter = contract.filters.Deposit(this.account);
            // Limit Polygon query to 2k blocks as RPCs don't seem to support more.
            const fromBlock =
              network === "polygon"
                ? (await contract.provider.getBlockNumber()) - 1990
                : undefined;
            const events = (await contract.queryFilter(filter, fromBlock)).map(
              (log) => {
                const parsed = contract.interface.parseLog(log);
                if (!parsed)
                  throw new Error("Failed to parse Deposit event log");
                return {
                  txHash: log.transactionHash,
                  ...parsed.args,
                };
              }
            );
            networkDeposits[network] = events;
            // Initialise actualWithdrawNonces  & filledWithdrawNonces objects.
            actualWithdrawNonces[network] = {};
            filledWithdrawNonces[network] = {};
            // Initialise filledWithdrawNonces with given deposit events.
            for (let event of events) {
              const withdrawNetwork = this.bridgeIdToNetwork[
                event.toId.toString()
              ];
              const filled = filledWithdrawNonces[network];
              if (!filled[withdrawNetwork]) filled[withdrawNetwork] = 0;
              filled[withdrawNetwork]++;
            }
            // Fetch actual withdraw nonces from this network.
            const withdrawNetworks = networks.filter((x) => x !== network);
            for (let withdrawNetwork of withdrawNetworks) {
              actualWithdrawNonces[network][
                withdrawNetwork
              ] = await contract.withdrawNonce(
                this.account,
                BridgeId[withdrawNetwork].toString()
              );
            }
            resolve();
          })
        );
      }
      await Promise.all(promises);
      const pendingDeposits = [];
      // Compare actual withdraw & filled withdraw values to find pending withdraws.
      for (let depositNetwork of networks) {
        for (let withdrawNetwork in filledWithdrawNonces[depositNetwork]) {
          const withdrawNonceRequired = parseInt(
            filledWithdrawNonces[depositNetwork][withdrawNetwork].toString()
          );
          const actualWithdrawNonce = parseInt(
            actualWithdrawNonces[withdrawNetwork][depositNetwork].toString()
          );
          if (withdrawNonceRequired === actualWithdrawNonce) continue;
          const bridgePrefix = `[${depositNetwork} -> ${withdrawNetwork}]`;
          console.log(
            `${bridgePrefix} actual withdraw nonce: ${actualWithdrawNonce}`
          );
          console.log(
            `${bridgePrefix} withdraw nonce required to complete bridging: ${withdrawNonceRequired}`
          );
          const withdrawNetworkId = BridgeId[withdrawNetwork];
          const deposits = networkDeposits[depositNetwork].filter((x) =>
            x.toId.eq(withdrawNetworkId)
          );
          const pendingCount = withdrawNonceRequired - actualWithdrawNonce;
          for (
            let i = deposits.length - pendingCount;
            i < deposits.length;
            i++
          ) {
            pendingDeposits.push({
              ...deposits[i],
              pendingIndex: i - (deposits.length - pendingCount),
            });
          }
        }
      }
      this.pendingUserDeposits = pendingDeposits;
      this.loadingPendingDeposits = false;
    },

    async withdraw(deposit) {
      this.withdrawing = true;

      try {
        closeAllNotifications();
        showNotification(
          "warning",
          "follow wallet instructions to approve withdrawal",
          undefined,
          0
        );

        const fromNetwork = this.bridgeIdToNetwork[deposit.fromId];
        const toNetwork = this.bridgeIdToNetwork[deposit.toId];

        // Ensure user is in the correct network.
        if (this.network !== toNetwork) await this.switchNetwork(toNetwork);

        closeAllNotifications();
        showNotification(
          "success",
          "processing withdrawal, please wait...",
          undefined,
          0
        );
        const signature = await getSignature(fromNetwork, deposit.txHash);

        const nonce = await this.bridgeContract.withdrawNonce(
          this.account,
          BridgeId[fromNetwork]
        );

        const receipt = await this.bridgeContract.withdraw(
          this.account,
          deposit.token,
          deposit.amount,
          nonce,
          BridgeId[fromNetwork],
          ethers.utils.arrayify(signature),
          {
            // gasLimit: ethers.BigNumber.from("2000000"),
          }
        );

        await receipt.wait(1);
        closeAllNotifications();
        showNotification("success", "withdrawal successful");

        this.$emit("withdrawal-successful");
      } catch (error) {
        console.log(error);
        closeAllNotifications();
        showNotification("error", error.message.toLowerCase(), undefined, 0);
      }
      this.withdrawing = false;
      this.fetchPendingDeposits();
    },

    async switchNetwork(network) {
      showNotification("warning", "confirm switch of network...", undefined, 0);
      this.switchingNetwork = true;
      await switchNetwork(network);
    },

    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@use "src/assets/styles/handle.fi.scss" as handle;
</style>
