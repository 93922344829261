import axios from "axios";

export const getSignature = async (network, txHash) => {
  if (!network) throw new Error("Unsupported Network");
  if (network === "homestead") network = "ethereum";
  const { data } = await axios.get(
    `https://handle-bridge.herokuapp.com/bridge/sign?network=${network}&transactionHash=${txHash}`
  );
  return data.signature;
};
