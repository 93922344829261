<template>
  <div class="uk-margin">
    <label
      v-if="!hideLabel"
      class="uk-form-label hfi-form-label-width-medium"
      :for="id === '' ? 'selectBridgeToken' : id"
    >
      {{ label ? label : "bridge token" }}
    </label>
    <div class="uk-form-controls">
      <Select
        :id="id === '' ? 'selectBridgeToken' : id"
        :class="boundaryClass"
        :boundaryClass="boundaryClass"
        selectClasses="uk-width-expand"
        inputClasses="uk-text-left"
        :options="supportedBridgeTokens"
        :icons="[
          'FOREXLogoLightBorder.svg',
          'fxAUDLogo.png',
          'fxPHPLogo.png',
          'fxCNYLogo.png',
          'fxEURLogo.png',
          'fxJPYLogo.png',
          'fxKRWLogo.png',
          'fxSGDLogo.png',
          'fxGBPLogo.png',
          'fxUSDLogo.png',
          'fxCHFLogo.png',
        ]"
        :value="value ? value : 'FOREX'"
        @change="setBridgeToken"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import Select from "@/components/Select";
import Token from "@/types/Token";
import { store } from "@/store";
import config from "@/contracts.config.json";

export default {
  name: "SelectBridgeToken",
  components: {
    Select,
  },
  props: {
    id: { type: String, default: "" },
    boundaryClass: { type: String, default: "select-bridge-token-boundary" },
    value: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: "" },
    hideLabel: { type: Boolean, default: true },
  },
  data() {
    return {
      Token,
    };
  },
  computed: {
    network() {
      return store.state.network;
    },
    supportedBridgeTokens() {
      return [
        ...(this.network && config[this.network].forex ? [Token.FOREX] : []),
        ...[Token.fxAUD, Token.fxPHP, Token.fxEUR],
      ];
    },
  },
  methods: {
    setBridgeToken(value) {
      this.$emit("change", value);
    },
  },
};
</script>
