<template>
  <form novalidate autocomplete="off" class="uk-form-width-large uk-margin-top">
    <fieldset class="uk-fieldset uk-width-1-1">
      <SelectBridgeToken
        id="bridgeToken"
        class="uk-margin-small-top bridgeToken-boundary"
        boundaryClass="bridgeToken-boundary"
        @change="(selection) => setBridgeToken(selection)"
        :disabled="!currentNetwork || !canBridge"
        :value="token"
      />

      <div class="uk-margin-top">
        <label
          class="uk-form-label uk-flex uk-width-expand uk-flex-between"
          for="bridgeAmount"
        >
          <span>amount</span>
          <span v-if="canBridge">
            <span> bal: {{ formatEther(balance) }} </span>
          </span>
        </label>

        <div class="uk-form-controls uk-position-relative uk-flex">
          <NumberInput
            class="uk-width-expand"
            id="bridgeAmount"
            type="number"
            :placeholder="
              currentNetwork ? 'tokens to bridge' : 'tokens to receive'
            "
            :value="inputAmount || amount"
            :alert="alert"
            :min="ethers.BigNumber.from(0)"
            @change="onSetAmount"
            :disabled="!currentNetwork || !canBridge"
          />
          <button
            v-if="currentNetwork && canBridge"
            class="uk-button hfi-button hfi-input-button"
            @click="getMaxBridge"
            :disabled="!currentNetwork || !canBridge"
          >
            max
          </button>
        </div>
      </div>
    </fieldset>
  </form>
</template>

<script>
import { store } from "@/store";
import { BigNumber, ethers } from "ethers";
import NumberInput from "@/components/NumberInput";
import { addEventListener, removeEventListener } from "@/utils/event";
import Event from "@/types/Event";
import SelectBridgeToken from "@/components/SelectBridgeToken";
import NetworkIcon from "@/components/NetworkIcon";
import { NETWORK_NAMES } from "@/utils/constants/networks";
import Token from "@/types/Token";

export default {
  name: "BridgeForm",
  props: {
    balance: { type: BigNumber },
    network: { type: String },
    currentNetwork: { type: Boolean },
    canBridge: { type: Boolean },
    inputAmount: { type: BigNumber },
    alert: { type: Boolean, default: false },
  },
  components: {
    SelectBridgeToken,
    NumberInput,
    NetworkIcon,
  },
  data() {
    return {
      loading: true,
      token: Token.FOREX,
      amount: ethers.BigNumber.from(0),
      ethers,
      Token,
      formatEther: (value, digits = this.token === Token.FOREX ? 4 : 2) =>
        (value
          ? parseFloat(ethers.utils.formatEther(value))
          : 0
        ).toLocaleString(undefined, this.digits(digits)),
    };
  },
  computed: {
    account() {
      return store.state.account;
    },
    stateToken() {
      return store.state.token;
    },
    networkDisplayName() {
      return NETWORK_NAMES[this.network];
    },
  },
  watch: {
    network() {
      this.setBridgeToken(store.state.token);
    },
    account() {
      this.setBridgeToken(store.state.token);
    },
    stateToken() {
      this.setBridgeToken(store.state.token);
    },
  },

  async mounted() {
    await this.initialiseState();
  },

  beforeDestroy() {
    removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
  },

  methods: {
    async initialiseState() {
      removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
      this.walletUpdateEventId = addEventListener(
        Event.WalletUpdate,
        this.resetState.bind(this)
      );
      await this.resetState();
    },
    clearForm() {
      this.amount = ethers.BigNumber.from(0);
    },
    async resetState() {
      this.loading = true;
      this.clearForm();

      await this.loadData();
      this.loading = false;
    },
    async loadData() {
      if (!this.account || !this.sdk) return;
      this.setBridgeToken(this.token);
    },

    async getMaxBridge(event) {
      event.preventDefault();
      this.amount = this.balance;
      await this.onSetAmount(this.amount);
    },

    async setBridgeToken(option) {
      this.token = option;
      this.clearForm();
      this.loading = true;
      this.$emit("setBridgeToken", option);
      await this.onSetAmount(ethers.BigNumber.from(0));
      this.loading = false;
    },
    /**
     * @param {BigNumber} value
     */
    async onSetAmount(value) {
      this.$emit("setAmount", value);
    },
    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
  },
};
</script>
